import React, { useCallback, useState } from "react"
import { graphql, Link } from "gatsby"
import LinkWrapper from "../components/Link"
import { Box, Flex } from "@singita/components"
import FilterWrapper, { FILTER_TYPES } from "../modules/FilterWrapper"
import HeroWrapper from "../modules/HeroWrapper"
import {
  Button,
  Card,
  ListItem,
  Gallery,
  Section,
  GridContainer,
  Container,
  Divider,
  ActionBar,
  Text,
  List,
} from "@singita/components"
import { Media } from "gatsby-singita-theme/src/Media"
import HelmetWrapper, {
  getJsonLd,
} from "gatsby-singita-theme/src/components/Helmet"
import { TEMPLATE_TYPES } from "gatsby-singita-theme/src/common/constants"
import SocialSharing from "../components/SocialSharing"
import {
  useContentModal,
  useActionBar,
  useSeo,
} from "gatsby-singita-theme/src/hooks"
import CardHeroWrapper from "../modules/CardHeroWrapper"
import Main from "gatsby-singita-theme/src/components/Main"
import GridItem from "../components/GridItem"
import ImageWrapper from "../modules/ImageWrapper"
import Layout from "../modules/Layout"
import FullModal from "../components/FullModal"
import DontationContainer from "../modules/Donation/DonationContainer"
import { getMetaDescription } from "../common/utils"

const DATA_MAP = {
  title: ["name"],
  description: ({ description }) => {
    if (description?.raw) {
      return getMetaDescription(JSON.parse(description.raw))
    } else {
      return null
    }
  },
  image: ({ hero, visual }) => {
    if (hero?.images?.length) {
      return hero.images[0].fluid.src
    } else if (visual?.image.fluid.src) {
      return visual.image.fluid.src
    } else {
      return null
    }
  },
  altText: ["name"],
  area: ["area", "name"],
  areaSlug: ["area", "connectedEntry", "slug"],
}

const ProjectsTemplate = props => {
  const {
    data: { project, allProjects, areas },
  } = props

  const [isOpen, setOpen] = useState(false)
  const projectsMapped = allProjects.edges.map(({ node }) => node)
  const [filteredProjects, setFilteredProjects] = useState(projectsMapped)
  const seoProps = useSeo(project, DATA_MAP)
  const jsonLd = getJsonLd({ ...seoProps }, TEMPLATE_TYPES.PROJECT)

  const { toggleModal, closeModal } = useContentModal()
  const [actionbarRef, isMobile] = useActionBar()

  const donationParams = {
    fundId: project.conservationPartner?.fundId ?? "",
    emailId: project.conservationPartner?.emailId ?? "",
    campaignId: project.campaignId ?? "",
  }

  const onSetFilter = useCallback(payload => setFilteredProjects(payload), [
    setFilteredProjects,
  ])

  return (
    <Main {...props} codes={{}}>
      <HelmetWrapper {...seoProps} schemaJsonLd={jsonLd} />
      {project.hero && (
        <Box mb={[20]}>
          <HeroWrapper {...project.hero} showPattern={false} />
        </Box>
      )}
      <Box data-testid="project-details-card" mb={[8]}>
        <CardHeroWrapper
          headline={project.headline}
          visual={project.visual}
          content={project?.description}
          visualPosition="left"
        >
          {!project.hideDonateButton && donationParams.fundId ? (
            <Button variant="primary" onClick={() => setOpen(true)}>
              Donate
            </Button>
          ) : !project.hideDonateButton &&
            project.conservationPartner?.donationLink ? (
            <LinkWrapper
              colorScheme="brandBrown"
              {...project.conservationPartner.donationLink}
            />
          ) : null}

          <List cols={1}>
            {project.projectDetails &&
              project.projectDetails.map((detail, index) => (
                <ListItem
                  data-testid={`project-detail-item-${index}`}
                  iconRight="chevron-right"
                  isSpaced={true}
                  py={[4]}
                  hasBorder={true}
                  onClick={() =>
                    toggleModal({
                      ...detail,
                      render: () =>
                        project.hideDonateButton ? null : donationParams.fundId ? (
                          <Button
                            variant="primary"
                            onClick={() => {
                              closeModal()
                              setOpen(true)
                            }}
                          >
                            Donate
                          </Button>
                        ) : project.conservationPartner?.donationLink ? (
                          <LinkWrapper
                            colorScheme="brandBrown"
                            {...project.conservationPartner.donationLink}
                          />
                        ) : null,
                    })
                  }
                  isLink={true}
                >
                  <Text size="body" fontWeight="bold">
                    {detail.headline}
                  </Text>
                </ListItem>
              ))}
          </List>

          <Flex data-testid="project-social-sharing" my={[3]}>
            <SocialSharing {...seoProps} />
          </Flex>
        </CardHeroWrapper>
      </Box>
      <ActionBar
        ref={actionbarRef}
        headline={
          isMobile ? null : project.name || project.structuredData.headline
        }
        description={isMobile ? null : project.area ? project.area.name : null}
        isSticky={true}
      >
        <Flex data-testid="project-action-bar-social" alignItems="center">
          {!isMobile && (
            <Box mr={[2]}>
              <Text ml={[0, 2]} size="small">
                Share:
              </Text>
            </Box>
          )}
          <SocialSharing {...seoProps} size={isMobile ? "small" : "default"} />
        </Flex>

        {!project.hideDonateButton && donationParams.fundId ? (
          <Button variant="primary" onClick={() => setOpen(true)}>
            Donate
          </Button>
        ) : !project.hideDonateButton &&
          project.conservationPartner?.donationLink ? (
          <LinkWrapper
            colorScheme="brandBrown"
            {...project.conservationPartner.donationLink}
          />
        ) : null}
      </ActionBar>
      {project.conservationPartner && !project.hideConservationPartner && (
        <Box data-testid="project-conservation-partner" mb={[8]}>
          <CardHeroWrapper
            headline={project.conservationPartner.name}
            subtitle="Conservation Partner"
            visualPosition="right"
            visual={{
              ...project.conservationPartner.visual,
              imgStyle: { objectFit: "cover" },
            }}
            content={project?.conservationPartner?.content}
          />
        </Box>
      )}
      {project.gallery ? <Layout layout={[project.gallery]} /> : null}

      <Container>
        <Divider my={[10]} sx={{ opacity: 0.5 }} />
      </Container>
      {filteredProjects.length > 0 && (
        <Container data-testid="project-other-projects">
          <Section headline={`Other ${project.area.name} Projects`} pb={[0]}>
            <Box width={["100%", "100%", "100%", "auto"]} mb={[5]}>
              <FilterWrapper
                items={projectsMapped}
                filter={FILTER_TYPES.COUNTRY}
                onSetFilter={onSetFilter}
                isVertical={false}
              />
            </Box>
            <Media greaterThanOrEqual="md">
              <GridContainer
                sx={{
                  gridTemplateColumns: [
                    "1fr",
                    "repeat(2, 1fr)",
                    "repeat(3, 1fr)",
                    "repeat(4, 1fr)",
                  ],
                  gridGap: [4],
                }}
              >
                {filteredProjects.map((proj, index) => (
                  <GridItem index={index} columns={[1, 2, 3, 4]}>
                    <Link
                      data-testid={`other-project-${index}`}
                      to={`/conservation/projects/${proj.slug}/`}
                    >
                      <Card
                        variant="small"
                        isLink={true}
                        headline={proj.name}
                        description={
                          proj.regions
                            ? `${proj.regions[0].name}, ${proj.regions[0].country}`
                            : null
                        }
                        image={<ImageWrapper image={proj?.visual?.image} />}
                      />
                    </Link>
                  </GridItem>
                ))}
              </GridContainer>
            </Media>
            <Media lessThan="md">
              <Gallery variant="carousel" overflow="visible">
                {filteredProjects.map(project => (
                  <Link to={`/conservation/projects/${project.slug}/`}>
                    <Card
                      variant="small"
                      isLink={true}
                      headline={project.name}
                      description={
                        project.regions
                          ? `${project.regions[0].name}, ${project.regions[0].country}`
                          : null
                      }
                      image={<ImageWrapper image={project?.visual?.image} />}
                    />
                  </Link>
                ))}
              </Gallery>
            </Media>
          </Section>
          <Divider my={[10]} sx={{ opacity: 0.5 }} />
        </Container>
      )}
      {areas.edges.length > 0 && (
        <Container data-testid="project-conservation-areas">
          <Section
            headline={`${areas.edges.length} Focus Areas of Conservation`}
          >
            <GridContainer
              sx={{
                gridTemplateColumns: [
                  "1fr",
                  "repeat(2,1fr)",
                  "repeat(3, 1fr)",
                  "repeat(3, 1fr)",
                ],
                gridGap: [4],
              }}
            >
              {areas.edges.map(({ node }, index) => (
                <GridItem index={index} columns={[1, 2, 3, 3]}>
                  <Link
                    data-testid={`conservation-area-${index}`}
                    to={`/${node.connectedEntry.slug}/`}
                  >
                    <Card
                      isLink={true}
                      variant="standard"
                      headline={node.name}
                      description={node.shortDescription.shortDescription}
                      image={<ImageWrapper image={node.image} />}
                    />
                  </Link>
                </GridItem>
              ))}
            </GridContainer>
          </Section>
        </Container>
      )}
      {!project.hideDonateButton && (
        <FullModal
          isOpen={isOpen}
          toggleModal={setOpen}
          title={`Donate: ${project.name}`}
        >
          <Box width={["100%", "100%", "100%", "100%", "50%"]} margin="auto">
            <DontationContainer {...donationParams} />
          </Box>
        </FullModal>
      )}
    </Main>
  )
}

export default ProjectsTemplate

export const PROJECTS_QUERY = graphql`
  query projectsQuery($slug: String!, $areaId: String!) {
    project: contentfulProjects(slug: { eq: $slug }) {
      name
      campaignId: eTapestryCampaignReference
      area {
        name
        connectedEntry {
          slug
        }
      }
      hero {
        ...HeroFields
      }
      regions {
        headline
        country
      }
      headline
      description {
        raw
        references {
          ...RichTextReferences
        }
      }
      visual {
        __typename
        ... on Node {
          ... on ContentfulImage {
            image {
              fluid(quality: 95, maxWidth: 930, maxHeight: 1000) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            altText
          }
          ... on ContentfulVideo {
            ...VideoFields
          }
        }
      }
      projectDetails {
        id
        headline
        content {
          raw
          references {
            ...RichTextReferences
            ...ContentFields
          }
        }
      }
      gallery {
        __typename
        ...GalleryFields
      }
      hideConservationPartner
      hideDonateButton
      conservationPartner {
        name
        fundId: eTapestryFundReference
        emailId: eTapestryEmailReference
        visual {
          __typename
          ... on ContentfulImage {
            image {
              fluid(quality: 95, maxWidth: 930, maxHeight: 1000) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
        content: description {
          raw
          references {
            __typename
            ...RichTextReferences
            ...ContentFields
          }
        }
        donationLink {
          label
          to
          external
          type
        }
      }
      structuredData {
        headline
        image {
          fluid(quality: 90, maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        description {
          description
        }
        altText
      }
    }
    allProjects: allContentfulProjects(
      filter: {
        node_locale: { eq: "en-US" }
        area: { id: { eq: $areaId } }
        slug: { ne: $slug }
      }
    ) {
      edges {
        node {
          name
          headline
          slug
          regions {
            country
            name
          }
          visual {
            __typename
            ... on ContentfulImage {
              image {
                fluid(quality: 90, maxWidth: 400, maxHeight: 260) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              altText
            }
          }
        }
      }
    }
    areas: allContentfulConservationArea(
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          name
          shortDescription {
            shortDescription
          }
          image {
            fluid(quality: 95, maxWidth: 465, maxHeight: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          connectedEntry {
            slug
          }
        }
      }
    }
  }
`
