import React from "react"
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share"
import { Share } from "@singita/components"

const SocialSharing = ({
  url,
  description,
  image,
  title,
  size = "default",
}) => {
  if (!url) return null

  return (
    <>
      <FacebookShareButton url={url} quote={description} image={image}>
        <Share icon="facebook" size={size} />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title} image={image}>
        <Share icon="twitter" size={size} />
      </TwitterShareButton>

      <LinkedinShareButton url={url} title={title} image={image}>
        <Share icon="linkedin" size={size} />
      </LinkedinShareButton>

      <PinterestShareButton url={url} description={title} media={image}>
        <Share icon="pinterest" size={size} />
      </PinterestShareButton>
    </>
  )
}

export default SocialSharing
